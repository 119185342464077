<template>
<div>
    <div class="header">
      <div class="kong"></div>
      <div class="logo">
        <img src="../assets/img/shenqing/校园足球LOGO.png" alt="" />
      </div>
      <div class="headerbei">
        <img src="../assets/img/header/xjzc.png" alt="" />
      </div>
    </div>
    <div class="main">
        <h2 style="text-align:center; font-size:30px;margin-bottom:20px;">【申报须知】</h2>
        <p class="first-paragraph">
            欢迎您进入新疆维吾尔自治区体育局“幼儿足球建设试点项目”申报系统，在申报前请认真阅读以下内容。
        </p>
        <p style="margin:20px 0;font-size:0.32rem;font-weight:bold;">只要您的园所满足以下条件:</p>
        <p>一、中国足球发展基金会新疆维吾尔自治区体育局“幼儿足球建设试点项目”，申请对象为项目区域内幼儿园。</p>
        <p>二、申报园所为自愿参加本项目，请您确保如实填写申报信息。</p>
        <p>三、申报园所需在系统平台下载电子版<span class="down-agree" @click="downAgree">《知情同意书》</span>，并认真阅读所有事项。园所自行打印贰份并由园长签署，提交至当地教育部门。</p>
        <p>四、申报园所需在系统平台进行注册及实名认证。</p>
        <p>五、园所确认申报后，将进入审核环节，审核通过后会由工作人员与您联系，您也可自行查询审核状态。</p>
        <p>申报疑问咨询热线：400-831-9799</p>
        <div class="certification">
            <!-- <p v-if="status != 4">{{statusObj[status]}}</p>
            <p v-else>{{schStatusObj[schStatus]}}</p> -->
            <p>{{statusObj[status]}}</p>
            <p>{{schStatusObj[schStatus]}}</p>
        </div>
        <div class="submit">
            <el-button type="primary" @click="renzhneg()" v-if="schStatus == 0 || schStatus == 2" style="margin-right:20px;">去认证</el-button>
            <!-- <el-button type="primary" @click="applyFn" :disabled="schStatus == 2 || schStatus == 0" v-if="status == 4">申报</el-button> -->
            <el-button type="primary" @click="applyFn" v-if="status == 4">申报</el-button>

            <el-button type="primary" @click="confirmFn" v-else>确定</el-button>
        </div>
    </div>
    <comdialog
        ref="comdialog"
        :params.sync="schoolInfo"
        @onSubmit="onSubmit"
        >
        <template slot="desc">
            <div class="template_desc">
            上传的证件必须和填写的社会统一信用代码或办学许可证号一致
            </div>
        </template>
    </comdialog>
</div>
</template>
<script>
import comdialog from "@/components/admin/editSchoolInfoDialog.vue";
export default {
    name:'shenbaoXj',
    data(){
        return {
            dialogVisible: true,
            schoolInfo:{},
            schStatus: 0,   //认证状态 0=未实名认证,88=已实名认证,1=资料审核中,2审核未通过,44=已冻
            status: 4,  //申报状态 0：已申请  【已提交申报信息， 资质审核中】 1：申报失败 【资质审核失败】 2：审核成功【成功完成政采名单】  3：审核失败【资质审核成功，但未进入最终政采名单】4：未申报
            statusObj:{
                0:'已申请',
                1:'园所实名认证审核失败',
                2:'审核成功',
                3:'审核失败',
                4:''
            },
            schStatusObj:{
                0:'园所未实名认证',
                88:'园所已实名认证',
                1:'园所实名认证审核中',
                2:'园所实名审核失败',
                44:'园所实名认证已冻'
            }
        }
    },
    components:{
        comdialog,
    },
    methods:{
        getStatus(){    //获取申报状态和认证状态
            let data = {
                schoolId: this.$store.state.userInfo.schoolId
            };
            this.api.admin.findStatus(data).then((res)=>{
                if(res.flag){
                    this.status = res.data.status;
                    this.schStatus = res.data.schStatus;
                }else{
                    this.$message({
                        message: res.message,
                        type: "error",
                    });
                }
                
            });
        },
        onSubmit(value){ //认证成功
            let val = JSON.parse(value);
            console.log(value);
            console.log(val);
            val.intefile = val.intefile.map((item) => {
                // console.log(item);
                if (typeof item === "string") {
                return item.split(".com")[1];
                }
                return item.url.split(".com")[1];
            });
            val.intefile = val.intefile.join(",");
            val.schoolLogo = val.schoolLogo.split(".com")[1];
            val.equment = val.equment || " ";
            val.square = val.square || " ";
            val.slogan = val.slogan || " ";
            // 提交接口
            this.api.admin
                .atteSchoolEdit(this.$store.state.userInfo.schoolId, val)
                .then((res) => {
                if (res.flag) {
                    this.$notice({
                    type: "success",
                    message: res.message,
                    }).isShow();
                    //获取状态
                    this.getStatus();
                } else {
                    this.$notice({
                    message: res.message,
                    }).isShow();
                }
                this.$refs.comdialog.isClose();
                // this.shiming = res.data;
                });
        },
        show() {
            this.api.admin
                .atteSchool(this.$store.state.userInfo.schoolId)
                .then((res) => {
                // 处理完善信息显示数据
                this.schoolInfo = { ...res.data };
                this.schoolInfo.schoolLogo = this.schoolInfo.schoolLogo
                    ? this.constant.URL_admin + this.schoolInfo.schoolLogo
                    : "";
                this.schoolInfo.type = parseInt(this.schoolInfo.type);

                if (this.schoolInfo.intefile) {
                    this.schoolInfo.intefile =
                    this.schoolInfo.intefile && this.schoolInfo.intefile.split(",");
                    this.schoolInfo.intefile =
                    this.schoolInfo.intefile &&
                    this.schoolInfo.intefile.map((item) => {
                        return { url: this.constant.URL_admin + item };
                    });
                } else {
                    this.schoolInfo.intefile = [];
                }
            });
        },
        //打开完善信息弹窗
        renzhneg() {
            this.$refs.comdialog.isOpen();
            // this.alertStatus = "edit";
        },
        applyFn() { //申报
            this.$alert(
                "申报资料提交完成",
                "新疆项目申请",
                {
                    confirmButtonText: "确定",
                    callback:action=>{
                        console.log(action,'dddd')
                        if(action === 'confirm'){
                            this.api.admin.addApply().then((res)=>{
                                if(res.flag){
                                    // this.$message({
                                    //     message: res.message,
                                    //     type: "success",
                                    // });
                                    this.$router.push({
                                        path:'/home'
                                    });
                                }else{
                                    this.$message({
                                        message: res.message,
                                        type: "error",
                                    });
                                }
                                
                            });
                        }
                    }
                }
            )
        },  
        confirmFn(){    //已申报点击确定按钮
            this.$router.push({
                path:'/home'
            });
        },
        downAgree(){    //下载知情同意书
            window.location.href = '/【签订版】知情同意书.pdf';
        }
    },
    created(){
        //获取申报状态和认证状态
        this.getStatus();
        //获取学校信息
        this.show();
    },
    mounted(){

    },
    
}
</script>
<style scoped>
    .logo {
    text-align: center;
    margin-top: 0.3rem /* 30/100 */;
    margin-bottom: 0.32rem /* 32/100 */;
    }
    .logo > img {
    width: 1.37rem /* 137/100 */;
    height: 1.37rem /* 137/100 */;
    }
    .kong {
    width: 100%;
    height: 0.3rem /* 30/100 */;
    background: #eeeced;
    }
    .headerbei {
        text-align: center;
        height: 5.7rem /* 560/100 */;
        line-height: 5.7rem /* 560/100 */;
        background-image: url("../assets/img/shenqing/11.jpg");
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: 0 -2.6rem /* 260/100 */;
    }
    .headerbei > img {
        width: 1040px;
    }
    .main{
        margin: 0.59rem auto 0 auto;
        /* padding:0 30px; */
        line-height: 28px;
        width: 11rem /* 1100/100 */;
        font-size: 0.24rem /* 24/100 */;
        font-family: Source Han Sans SC;
        color: #2b0200;
    }
    .main p{
        margin-bottom: 20px;
        line-height: 40px;
    }
    .first-paragraph{
        margin-bottom: 10px;
        text-indent: 2em;
    }
    .certification{
        margin-top: 20px;
        text-align: center;
    }
    .certification p{
        /* display: inline-block; */
    }
    .certification button{
        margin-left: 50px;
        padding-left:40px;
        padding-right:40px;
        background: #2b0200;
        color: #fff;
        font-size: 0.3rem /* 30/100 */;
        border:none;
    }
    .submit{
        margin:20px 0;
        text-align: center;
    }
    .submit button{
        padding-left:40px;
        padding-right:40px;
        background: #2b0200;
        color: #fff;
        margin: 0 auto;
        font-size: 0.3rem /* 30/100 */;
        border:none;
    }
    .down-agree{
        color:red;
        font-weight: bold;
        cursor: pointer;
    }
</style>