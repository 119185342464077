<template>
  <div class="dialog_block">
    <el-dialog
      title="完善信息"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="isClose"
      :visible.sync="show"
    >
      <el-form
        ref="comForm"
        :model="form"
        style="padding: 0 0.5rem"
        label-width="1.5rem"
      >
        <el-form-item
          label="审核未通过原因："
          v-show="
            $store.state.schoolInfo.atteSchool.status == '2' &&
            $store.state.schoolInfo.atteSchool.rejectReason
          "
        >
          <span style="color: #ff0000">{{
            $store.state.schoolInfo.atteSchool.rejectReason
          }}</span>
        </el-form-item>
        <template v-for="item in formList">
          <el-form-item
            :key="item.prop"
            :label="item.label"
            :prop="item.prop || ''"
            :required="item.required || false"
            :rules="item.rules || null"
            :size="item.size || ''"
          >
            <el-input
              v-if="item.type === 'text'"
              :disabled="item.disabled"
              v-model="form[item.prop]"
            ></el-input>
            <el-input
              v-else-if="item.type === 'number'"
              :disabled="item.disabled"
              v-model="form[item.prop]"
              
            ></el-input>
            <el-input
              v-else-if="item.type === 'password'"
              type="password"
              :disabled="item.disabled"
              v-model="form[item.prop]"
            ></el-input>
            <el-select
              v-else-if="item.type === 'select'"
              :disabled="item.disabled"
              style="width: 100%"
              :multiple="item.multiple || false"
              v-model="form[item.prop]"
              placeholder="请选择"
            >
              <el-option
                v-for="val in item.options"
                :key="val.value"
                :label="val.label"
                :value="val.value"
              >
              </el-option>
            </el-select>
            <el-upload
              v-else-if="item.type === 'upload-only'"
              :headers="{ token: $store.state.userInfo.token }"
              class="avatar-uploader"
              :action="constant.UPLOAD_URL + '/file/upload'"
              :show-file-list="false"
              :on-success="(v) => handleAvatarSuccess(v, item.prop)"
              :before-upload="beforeAvatarUpload"
            >
              <img v-if="schoolLogo" :src="schoolLogo" class="avatar" />
              <img
                v-else-if="form[item.prop]"
                :src="form[item.prop]"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <el-upload
              v-else-if="item.type === 'upload-list'"
              :headers="{ token: $store.state.userInfo.token }"
              :on-success="
                (res, file, list) => onSuccess(res, file, list, item.prop)
              "
              class="avatar-uploader"
              :action="constant.UPLOAD_URL + '/file/upload'"
              :file-list="form[item.prop]"
              :show-file-list="true"
              :limit="10"
              :on-exceed="(v) => handleImageExceed(v)"
              list-type="picture-card"
              :on-preview="(v) => handlePictureCardPreview(v, item.prop)"
              :on-remove="(v) => handleRemove(v, item.prop)"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-date-picker
              v-else-if="item.type === 'date'"
              style="width: 100%"
              v-model="form[item.prop]"
              :disabled="item.disabled"
              type="date"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd HH:MM:mm"
              placeholder="选择日期"
            >
            </el-date-picker>
            <el-radio-group
              v-else-if="item.type === 'radio'"
              :disabled="item.disabled"
              v-model="form[item.prop]"
            >
              <el-radio
                :label="val.value"
                v-for="val in item.options"
                :key="val.value"
                >{{ val.label }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
        </template>
      </el-form>
      <slot name="desc"></slot>
      <div style="text-align: center">
        <el-button type="primary" @click="submitForm">提交</el-button>
        <el-button @click="isClose">取消</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "tablePage",
  components: {},
  props: {
    // 初始表单数据
    params: {
      type: Object,
      default: () => {
        return {};
      },
    },
    // edit是否是编辑状态
    edit: {
      type: String,
      default: "add",
    },
  },
  watch: {
    params: {
      immediate: true,
      handler: function (val) {
        this.form = val;
      },
    },
  },
  data() {
    var checkSlogan = (rule, value, callback) => {
      if (!this.common.validateLength(value,20)) {
        callback(new Error("请输入正确园所标语"));
      } else {
        callback();
      }
    };
    return {
      form: {},
      show: false,
      formList: [
        {
          prop: "schoolName",
          type: "text",
          label: "学校名称",
          disabled: true,
          rules: [
            { required: true, message: "请输入学校名称", trigger: "blur" },
          ],
        },
        {
          prop: "xydm",
          type: "text",
          label: "社会统一信用代码或办学许可证号",
          disabled: true,
          rules: [
            {
              required: true,
              message: "请输入社会统一信用代码或办学许可证号",
              trigger: "blur",
            },
          ],
        },
        {
          prop: "city",
          type: "text",
          label: "所在城市",
          disabled: true,
          rules: [
            { required: true, message: "请输入所在城市", trigger: "blur" },
          ],
        },
        {
          prop: "address",
          type: "text",
          label: "详细地址",
          rules: [
            { required: true, message: "请输入详细地址", trigger: "blur" },
          ],
        },
        {
          prop: "schoolLogo",
          type: "upload-only",
          label: "学校LOGO",
          /* rules: [{ required: true, message: "请上传", trigger: "change" }], */
        },
        {
          prop: "square",
          type: "number",
          label: "活动场地平米数",
          // rules: [
          //   { required: true, message: "请输入在校学员人数", trigger: "blur" },
          // ],
        },
        {
          prop: "equment",
          type: "text",
          label: "运动器材",
          // rules: [
          //   { required: true, message: "请输入在校学员人数", trigger: "blur" },
          // ],
        },
        {
          prop: "number",
          type: "text",
          label: "在校学员人数",
          disabled: true,
          rules: [
            { required: true, message: "请输入在校学员人数", trigger: "blur" },
          ],
        },
        {
          prop: "contacts",
          type: "text",
          label: "联系人姓名",
          disabled: true,
          rules: [
            { required: true, message: "请输入联系人姓名", trigger: "blur" },
          ],
        },
        {
          prop: "contactsMobile",
          type: "number",
          label: "联系人电话 ",
          disabled: true,
          rules: [
            { required: true, message: "请输入联系人电话", trigger: "blur" },
          ],
        },
        {
          prop: "type",
          type: "radio",
          label: "学校类型",
          multiple: true,
          disabled: true,
          rules: [
            { required: true, message: "请选择学校类型", trigger: "change" },
          ],
          options: [
            {
              label: "公立",
              value: 0,
            },
            {
              label: "私立",
              value: 1,
            },
          ],
        },
        {
          prop: "slogan",
          type: "text",
          label: "园所标语",
           rules: [
            { required: false, validator: checkSlogan, trigger: "blur" },
          ],
        },
        {
          prop: "intefile",
          type: "upload-list",
          label: " 上传营业执照或办学许可证",
          rules: [
            { required: false, message: "请上传图片", trigger: "change" },
          ],
        },
      ],
      //上传
      dialogImageUrl: "",
      showPicLsit: [], // 接口返回图片列表
      fileList: [],
      dialogVisible: false,
      schoolLogo: "",
    };
  },
  created() {},
  mounted() {},
  methods: {
    handleAvatarSuccess(res, prop) {
      console.log(res, prop);
      this.form[prop] = res.data.url;
      this.schoolLogo = res.data.url;
      // this.form[prop] = res.data.filename
    },
    // 再次输入密码验证
    handleAgainPassword(rule, value, callback) {
      console.log(this.form, this.edit);
      if (!value && this.edit === "add") {
        callback(new Error("请再次输入密码"));
      } else if (value && this.form.password && value != this.form.password) {
        callback(new Error("两次输入密码不一致"));
      } else {
        callback();
      }
    },
    handleImageExceed(file) {
      console.log(file);
      this.$message.warning("最多上传10张图片");
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$notice({
          message: "上传头像图片只能是 JPG/PNG 格式!",
        }).isShow();
      }
      if (!isLt2M) {
        this.$notice({
          message: "上传头像图片大小不能超过 2MB!",
        }).isShow();
      }
      return isJPG && isLt2M;
    },
    submitForm() {
      const _val = "comForm";
      const self = this;
      // eslint-disable-next-line consistent-return
      return self.$refs[_val].validate((valid) => {
        self.form.verifyBatchName = "资料审核中";
        JSON.stringify(localStorage.setItem("biao", self.form.slogan));
        if (valid) {
          if(self.form.name){
            self.form.name = self.form.name.trim();
          }
          if(self.form.idCard){
            self.form.idCard = self.form.idCard.trim();
          }
          if(self.form.phone){
            self.form.phone = self.form.phone.trim();
          }
          
          this.$emit("onSubmit", JSON.stringify(self.form));
        } else {
          this.$notice({
            message: "请检查已填入信息",
          }).isShow();
          return false;
        }
      });
    },
    // 上传多个时使用
    //start
    onSuccess(res, file, list, prop) {
      this.form[prop].push(res.data.url);
    },
    handleRemove(file, prop) {
      this.form[prop].forEach((item, index) => {
        if (item.url === file.url) {
          this.form[prop].splice(index, 1);
        }
      });
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    //end
    isOpen() {
      this.show = true;
    },
    isClose() {
      this.show = false;
      this.$emit("close", "");
    },
  },
};
</script>

<style lang="scss" scoped></style>
<style lang="scss">
.dialog_block {
  .el-dialog {
    margin-top: 10vh !important;
  }

  .el-form-item {
    margin-bottom: 22px;
  }

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}
</style>